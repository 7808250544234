import Vue from "vue/dist/vue.min";

const ContentStage = () => import(/* webpackChunkName: "ContentStage" */ "./ContentStage.vue");

function init() {
    document.querySelectorAll('[data-vue="content-stage"]').forEach((el) => {
        return new Vue({
            el,
            components: { ContentStage },
        });
    });
}

if (document.readyState !== "loading") {
    init();
} else {
    document.addEventListener("DOMContentLoaded", () => {
        init();
    });
}
